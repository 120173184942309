import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.css';
import Landing from './views/Landing';
import Chisiamo from './views/Chisiamo';
import Caratteristiche from './views/Caratteristiche';
import Demo from './views/Demo';
import Cookie from './views/Cookie';
import Privacy from './views/Privacy';
import reportWebVitals from './reportWebVitals';
import {CookieBanner} from "./components/cookie";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Analytics } from "@vercel/analytics/react";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
    // errorElement: <ErrorPage />,
  },
  {
    path: "chi-siamo",
    element: <Chisiamo />,
  },
  {
    path: "caratteristiche",
    element: <Caratteristiche />,
  },
  {
    path: "demo",
    element: <Demo />,
  },
  {
    path: "cookie",
    element: <Cookie />,
  },
  {
    path: "privacy",
    element: <Privacy />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));

console.log(process.env)

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Helmet>
        <title>{process.env.REACT_APP_TITLE}</title>
        {/* <link rel="canonical" href={pageURL} /> */}
      </Helmet>
      <h1>{process.env.REACT_APP_TITLE}</h1>
    </HelmetProvider>
    <SpeedInsights />
    <Analytics />
    <CookieBanner
      // showBanner={true}
      message="Utilizziamo i cookie per personalizzare contenuti ed annunci, per fornire funzionalità dei social media e per analizzare il nostro traffico. Condividiamo inoltre informazioni sul modo in cui utilizza il nostro sito con i nostri partner che si occupano di analisi dei dati web, pubblicità e social media, i quali potrebbero combinarle con altre informazioni che ha fornito loro o che hanno raccolto dal suo utilizzo dei loro servizi."
      wholeDomain={true}
      showPreferencesOption={false}
      onAccept={() => {
        console.log('load necessary cookies')
      }}
      onAcceptPreferences={() => {
        console.log('load preferences cookies')
      }}
      onAcceptStatistics={() => {
        console.log('load statistics cookies')
      }}
      onAcceptMarketing={() => {
        console.log('load marketing cookies')
      }}
    />

    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
