import React from 'react';
import "../footer.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import LogoWeb from "../assets/img/logo_web.png";
import FondiRegione from "../assets/img/fondi_po_fesr_2014-2020.png";
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer className="flex-1 bg-blue text-white">
      <div className="flex flex-col lg:flex-row items-center gap-12 lg:gap-3 container mx-auto py-12 lg:py-14 xl:py-20">
        <div className="flex-1 w-full lg:w-auto">
          <img className="h-20 md:h-50" src={LogoWeb} alt="Software Ristorazione Ospedaliera" title="MOHS - Meals Organizer for Hospital System" />
        </div>
        <div className="flex-1 w-full lg:w-auto">
          <img className="h-20 md:h-20" src={FondiRegione} alt="Software Ristorazione Ospedaliera" title="MOHS - Meals Organizer for Hospital System" />
        </div>
        <div className="flex-1 w-full flex flex-col gap-12 lg:gap-6 justify-end">
          <div className="flex flex-col lg:flex-row items-start lg:items-center lg:justify-end gap-12 lg:gap-14 w-full lg:w-auto max-w-[100vw]">
            <ul className="flex flex-col lg:flex-wrap lg:flex-row lg:items-center gap-6" style={{color: 'rgb(255, 255, 255)'}}>
              <li className="border-b-2" style={{borderColor: 'rgb(255, 255, 255)'}}>
                <Link to="/" className="block py-1.5 font-light">Home</Link>
              </li>
              <li className="border-b-2" style={{borderColor: 'transparent'}}>
                <Link to="/chi-siamo" className="block py-1.5 font-light">Chi Siamo</Link>
              </li>
              <li className="border-b-2" style={{borderColor: 'transparent'}}>
                <Link to="/caratteristiche" className="block py-1.5 font-light">Caratteristiche</Link>
              </li>
              <li className="border-b-2" style={{borderColor: 'transparent'}}>
                <Link to="/demo" className="block py-1.5 font-light">Demo</Link>
              </li>
            </ul>
            <ul className="flex items-center gap-x-7 lg:gap-x-4 gap-y-2 flex-wrap">
              <li>
                <a target="_blank" href="https://facebook.com/">
                  <FontAwesomeIcon icon={brands('facebook')} size='lg' />
                </a>
              </li>
              <li>
                <a target="_blank" href="https://linkedin.com/">
                  <FontAwesomeIcon icon={brands('linkedin')} size='lg' />
                </a>
              </li>
            </ul>
          </div>
          <span className="text-base lg:text-right">Developed By <a target="_blank" className="underline" style={{color: 'rgb(255, 255, 255)'}} href="//www.splitweb.it">SPLIT ® | GL S.r.l.s.</a></span>
        </div>
      </div>
    </footer>
  );
}
