import React, { useState } from "react";
import Navbar from "../components/Navbar.js";
import Footer from "../components/Footer.js";
import Card from "../components/Card.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular, brands, icon } from "@fortawesome/fontawesome-svg-core/import.macro"; // <-- import styles to be used

import logo from "../assets/img/logo.svg";
import headerimg from "../assets/img/slide.jpg";
import Timing from "components/Timing.js";
import Header from "components/Header.js";
import Section from "components/Section.js";
import HubspotForm from "react-hubspot-form";

export default function Chisiamo() {
  const [formStatus, setFormStatus] = useState(undefined);

  return (
    <>
      <Header />
      <Section secondary>
        <div className="container mt-3 mx-auto py-12 lg:py-14 xl:py-20">
          <div className="prose rich-text-block max-w-5xl text-left">
            <h1>Software Refezione Ospedaliera</h1>
            <p>
              MOHS è un software progettato per aiutare le Aziende di Ristorazione Collettiva e le Strutture Ospedaliere a gestire in modo efficiente la preparazione e la fornitura di pasti ai
              pazienti. Offre un sistema di gestione del vassoio personalizzato al letto del degente e di gestione del centro cottura ospedaliero, consentendo agli operatori sanitari di monitorare in
              modo proattivo le attività di preparazione e distribuzione dei pasti e delle diete personalizzate. Il software è stato progettato per offrire una gestione efficiente delle risorse
              alimentari, ottimizzando la gestione dei pasti e dei materiali di consumo.
            </p>
          </div>
        </div>
      </Section>
      <Section>
        <div className="container mx-auto py-12 lg:py-14 xl:py-20">
          <div className="flex flex-col w-full gap-10 lg:gap-20 lg:flex-row">
            <div className="prose w-full lg:w-1/2 lg:mt-10">
              <h2 className="text-body lg:text-4xl font-medium mb-2">Contattaci</h2>
              <p className="lg:leading-6">
                Azienda di Ristorazione Ospedaliera o Gara d'Appalto in scadenza? <br />
                Compila il modulo, teniamoci in contatto!{" "}
              </p>
              <p className="lg:leading-6">Abbiamo molte soluzioni per te e ti aiuteremo a redigere l'offerta tecnica.</p>
            </div>
            <div className="w-full lg:w-1/2">
              <HubspotForm
                region="eu1"
                portalId="145175428"
                formId="806a56b0-10c7-464d-b1a7-8de1d649e040"
                locale="it"
                onSubmit={() => console.log("Submit!")}
                onReady={(form) => console.log("Form ready!")}
                loading={<div>Loading...</div>}
              />
            </div>
          </div>
        </div>
      </Section>
      <Footer />
    </>
  );
}
