import React from 'react';
import Section from "../components/Section";
import Footer from "../components/Footer.js";
import Card from "../components/Card.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

import logo from "../assets/img/logo.svg";
import headerimg from "../assets/img/slide.jpg";
import Timing from 'components/Timing.js';
import Hero from "../components/Hero";
import Header from 'components/Header.js';

export default function Chisiamo() {
  return (
    <>
    <Header />
    <div className="overflow-x-hidden">
      <Section>
        <div className="container max-w-full px-4 md:px-6 mt-24">
          <div className="flex flex-col gap-4 items-center text-center">
            <h1 className="text-3xl font-bold tracking-tighter sm:text-5xl">Software Ristorazione Collettiva Ospedaliera</h1>
            <p className="md:text-xl xl:text-xl">
              La corretta alimentazione è fondamentale per la guarigione dei pazienti. La cucina dell'ospedale offre un
              menu personalizzato, cibo fresco e di qualità, una varietà di opzioni e molto altro per garantire che i
              pazienti ricevano pasti deliziosi e nutrizionalmente equilibrati durante il loro soggiorno.
            </p>
          </div>
        </div>
      </Section>
      <Section>
        <div className='container max-w-full px-4 md:px-6 my-5'>
          <h2 className="text-2xl font-bold tracking-tighter items-center text-center sm:text-3xl">MOHS - Meals Organizer for Hospital System</h2>
          <div className="mx-auto my-5 grid items-start gap-1 sm:grid-cols-2 md:gap-5 lg:max-w-full lg:grid-cols-3">
            <div className="grid gap-1">
              <h3 className="text-lg font-bold">Interfaccia Intuitiva e Accessibile</h3>
              <p className="text-sm">
                MOHS offre un'interfaccia user-friendly, garantendo facilità di utilizzo per operatori sanitari, tecnologi alimentari e dietisti.
                La sua accessibilità via smartphone semplifica la gestione dei pasti in qualsiasi contesto ospedaliero.
              </p>
            </div>
            <div className="grid gap-1">
              <h3 className="text-lg font-bold">Personalizzazione Avanzata dei Menu</h3>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                La suite consente la creazione e gestione di menu personalizzati, adattabili alle esigenze specifiche dei pazienti, considerando restrizioni alimentari, allergie e preferenze.
              </p>
            </div>
            <div className="grid gap-1">
              <h3 className="text-lg font-bold">Integrazione con Prescrizioni Mediche</h3>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                MOHS collega le scelte alimentari alle prescrizioni mediche, garantendo una corretta selezione e personalizzazione dei pasti.
                Questa integrazione riduce il rischio di errori nella pianificazione nutrizionale.
              </p>
            </div>
            <div className="grid gap-1">
              <h3 className="text-lg font-bold">Consegna Precisa con Vassoi Personalizzati</h3>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                L'utilizzo di vassoi a letto del paziente assicura una consegna precisa e tempestiva dei pasti, mantenendo la qualità del cibo e migliorando l'esperienza complessiva dei pazienti.
              </p>
            </div>
            <div className="grid gap-1">
              <h3 className="text-lg font-bold">Monitoraggio Costante e Analisi dei Dati</h3>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                MOHS fornisce una panoramica dettagliata del servizio di ristorazione, consentendo il monitoraggio costante e l'analisi delle prestazioni.
                Questi dati sono utilizzati per miglioramenti continui al sistema.
              </p>
            </div>
            <div className="grid gap-1">
              <h3 className="text-lg font-bold">Adattamento Terapeutico dei Pasti</h3>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Il software programma pasti specifici per soddisfare requisiti clinici e nutrizionali, contribuendo a ridurre al minimo gli errori nella gestione delle diete speciali.
              </p>
            </div>
            <div className="grid gap-1">
              <h3 className="text-lg font-bold">Collaborazione Interdisciplinare</h3>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Coinvolgendo operatori sanitari, tecnologi alimentari e dietisti, MOHS assicura una gestione integrata e personalizzata dei pasti, rispondendo alle esigenze complesse del contesto ospedaliero.
              </p>
            </div>
            <div className="grid gap-1">
              <h3 className="text-lg font-bold">Efficienza Operativa e Conformità Normativa</h3>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                MOHS migliora l'efficienza complessiva delle operazioni di preparazione e approvvigionamento dei prodotti alimentari, garantendo nel contempo il rispetto delle norme igieniche e delle regolamentazioni.
              </p>
            </div>
            <div className="grid gap-1">
              <h3 className="text-lg font-bold">Feedback Positivo e Ottimizzazione delle Risorse</h3>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                La suite contribuisce alla gestione razionale dei costi, riducendo gli sprechi e offrendo un impatto positivo sul bilancio ospedaliero.
                I feedback positivi dei pazienti testimoniano il successo ottenuto grazie a questa innovativa soluzione.
              </p>
            </div>
          </div>
          <h4 className="text-2xl font-bold tracking-tighter items-center text-center sm:text-3xl">
            Con MOHS, trasformiamo la ristorazione ospedaliera in un'esperienza personalizzata, efficiente e sicura
          </h4>
        </div>
      </Section>
      <Footer />
    </div>
    </>
  );
}
