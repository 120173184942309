import React from 'react';
import Navbar from "../components/Navbar.js";
import Footer from "../components/Footer.js";
import Card from "../components/Card.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

import logo from "../assets/img/logo.svg";
import headerimg from "../assets/img/slide.jpg";
import Timing from 'components/Timing.js';
import Header from 'components/Header.js';
import Section from 'components/Section.js';

export default function Chisiamo() {
  return (
    <>
      <Header />
      
      <Section>
        <div className="container mt-3 mx-auto py-12 lg:py-14 xl:py-20">
          <div className="prose rich-text-block max-w-5xl text-left" >
            <h3>MOHS - Meals Organizer for Hospital System</h3>
            <p>MOHS è un software progettato per aiutare le Aziende di Ristorazione Collettiva e le Strutture Ospedaliere a gestire in modo efficiente la preparazione e la fornitura di pasti ai pazienti. Offre un sistema di gestione del vassoio personalizzato al letto del degente e di gestione del centro cottura ospedaliero, consentendo agli operatori sanitari di monitorare in modo proattivo le attività di preparazione e distribuzione dei pasti e delle diete personalizzate.
              Il software è stato progettato per offrire una gestione efficiente delle risorse alimentari, ottimizzando la gestione dei pasti e dei materiali di consumo.</p>
          </div>
        </div>
      </Section>

      <Footer />
    </>
  );
}
