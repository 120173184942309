import React, { useState } from "react";
import Hero from "./Hero";
import Hero_bg from "../assets/img/hero_bg.jpg";
import Section from "./Section";
import Card from "./Card";
import ImageTextSection from "./ImageTextSection";
import PrenotazioneDegente from "../assets/img/prenotazione_degente.jpg";
import VariazioniReparto from "../assets/img/variazioni_dal_reparto.jpg";
import ElaborazioneDati from "../assets/img/elaborazione_dati_centro_cottura.jpg";
import GeneriExtra from "../assets/img/generi_extra_derrate.jpg";
import DieteSpeciali from "../assets/img/dieta_personalizzata_grammata.jpg";
import PianificazioneStatistica from "../assets/img/pianificazione_statistica.jpg";
import VassoioPersonalizzato from "../assets/img/vassoio_personalizzato_degente.jpg";
import DietaPersonalizzata from "../assets/img/dieta_personalizzata.jpg";
import Somministrazione from "../assets/img/somministrazione_pasti.jpg";
import HubspotForm from "react-hubspot-form";

export default function Body(props) {
  const [formStatus, setFormStatus] = useState(undefined);

  return (
    <div className="overflow-x-hidden">
      <Hero
        title="Software Ristorazione Ospedaliera"
        content="MOHS - Meals Organizer for Hospital System è un software per la gestione della logistica di un centro cottura ospedaliero. La Suite di Software a corredo permettono di gestire con semplicità e velocità la distribuzione di pasti e delle diete personalizzate, garantendo la tracciabilità."
        image={Hero_bg}
      />

      <Section secondary>
        <div className="container mx-auto py-12 lg:py-14 xl:py-20">
          <div className="prose rich-text-block max-w-5xl text-right ml-auto mr-0">
            <h2>MOHS - Meals Organizer for Hospital System</h2>
            <p>
              MOHS è un software progettato per aiutare le Aziende di Ristorazione Collettiva e le Strutture Ospedaliere a gestire in modo efficiente la preparazione e la fornitura di pasti ai
              pazienti. Offre un sistema di gestione del vassoio personalizzato al letto del degente e di gestione del centro cottura ospedaliero, consentendo agli operatori sanitari di monitorare in
              modo proattivo le attività di preparazione e distribuzione dei pasti e delle diete personalizzate. Il software è stato progettato per offrire una gestione efficiente delle risorse
              alimentari, ottimizzando la gestione dei pasti e dei materiali di consumo.
            </p>
          </div>
        </div>
      </Section>

      <Section>
        <div className="container mx-auto py-12 lg:py-12">
          <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 justify-start">
            <Card
              align="left"
              image={PrenotazioneDegente}
              title="Prenotazione a letto del degente"
              content=" La prenotazione informatizzata, veloce e semplice, mediante l'utilizzo di semplici Smartphone Android opportunamente configurati permettono di accedere alla nostra Suite di Software per prenotare, letto per letto, i pasti dei degenti ospedalieri nel rispetto del capitolato.
                        La rilevazione del vitto, collegata al server in cloud, permette di specificare per ogni degente le specifiche della prescrizione medica e dietetica così da comporre, sulla base dei menu caricati dal centro cottura, il vassoio personalizzato direttamente al letto del degente con i soli pasti che è possibile scegliere in quel determinato giorno e per quelle determinate esigenze terapiche, nutrizionali, bromatologiche, etniache.
                        Una volta inviati i dati alla cucina e confermate le prenotazioni, in automatico verrà notificato il personale di reparto che potrà accedere al portale per comunicare eventuali variazioni.
                      "
            />

            <Card
              align="left"
              image={VariazioniReparto}
              title="Variazioni Web dal Reparto"
              content=" Le numerosissime funzioni della nostra Suite di Software per la Ristorazione Ospedaliera includono anche la possibilità per il personale dell'ospedale (Caposala, Coordinatori, Infermieri, Assistenti, Dirigenti) di accedere ad un portale a loro dedicato per comunicare eventuali variazioni, nuovi ricoveri e/o dimissioni.
                        Per ogni utente abilitato ad accedere al sistema informatico, vengono rilasciate delle credenziali di accesso personalizzate con le quali potrà visualizzare i reparti di sua competenza, modificare i dati entro un determinato orario e validarne la congruità, stampare dei riepiloghi e monitorare l'andamento del servizio di refezione ospedaliera.
                        Ciò consente di ridurre notevolmente i tempi di prenotazione, variazione e rendicontazione sia per l'azienda ospedaliera che per l'azienda di ristorazione collettiva.
                      "
            />

            <Card
              align="left"
              image={ElaborazioneDati}
              title="Elaborazione Dati Centro Cottura"
              content="MOHS è una soluzione di gestione del vassoio personalizzato al letto del degente e del centro cottura ospedaliero che offre ai gestori degli ospedali una soluzione di pianificazione e gestione dei pasti in modo efficiente e complessivo. MOHS aiuta a gestire le successive operazioni di ristorazione, come la consegna del cibo all'ospedale, la personalizzazione del menu, la preparazione e l'approvvigionamento dei prodotti alimentari, l'analisi dei costi e le operazioni di servizio al tavolo. MOHS offre una soluzione completa per la gestione della ristorazione ospedaliera, in modo che i gestori possano monitorare in modo efficiente tutti i processi e garantire la qualità del servizio fornito ai pazienti."
            />

            <Card
              align="left"
              image={GeneriExtra}
              title="Generi Extra di Conforto"
              content="MOHS è una soluzione di gestione del vassoio personalizzato al letto del degente e del centro cottura ospedaliero che offre ai gestori degli ospedali una soluzione di pianificazione e gestione dei pasti in modo efficiente e complessivo. MOHS aiuta a gestire le successive operazioni di ristorazione, come la consegna del cibo all'ospedale, la personalizzazione del menu, la preparazione e l'approvvigionamento dei prodotti alimentari, l'analisi dei costi e le operazioni di servizio al tavolo. MOHS offre una soluzione completa per la gestione della ristorazione ospedaliera, in modo che i gestori possano monitorare in modo efficiente tutti i processi e garantire la qualità del servizio fornito ai pazienti."
            />

            <Card
              align="left"
              image={DieteSpeciali}
              title="Diete e Grammature"
              content="MOHS è una soluzione di gestione del vassoio personalizzato al letto del degente e del centro cottura ospedaliero che offre ai gestori degli ospedali una soluzione di pianificazione e gestione dei pasti in modo efficiente e complessivo. MOHS aiuta a gestire le successive operazioni di ristorazione, come la consegna del cibo all'ospedale, la personalizzazione del menu, la preparazione e l'approvvigionamento dei prodotti alimentari, l'analisi dei costi e le operazioni di servizio al tavolo. MOHS offre una soluzione completa per la gestione della ristorazione ospedaliera, in modo che i gestori possano monitorare in modo efficiente tutti i processi e garantire la qualità del servizio fornito ai pazienti."
            />

            <Card
              align="left"
              image={PianificazioneStatistica}
              title="Pianificazione & Statistica"
              content="MOHS è una soluzione di gestione del vassoio personalizzato al letto del degente e del centro cottura ospedaliero che offre ai gestori degli ospedali una soluzione di pianificazione e gestione dei pasti in modo efficiente e complessivo. MOHS aiuta a gestire le successive operazioni di ristorazione, come la consegna del cibo all'ospedale, la personalizzazione del menu, la preparazione e l'approvvigionamento dei prodotti alimentari, l'analisi dei costi e le operazioni di servizio al tavolo. MOHS offre una soluzione completa per la gestione della ristorazione ospedaliera, in modo che i gestori possano monitorare in modo efficiente tutti i processi e garantire la qualità del servizio fornito ai pazienti."
            />
          </div>
        </div>
      </Section>

      <Section>
        <div className="container mx-auto py-12 lg:py-12">
          <ImageTextSection
            reverse
            image={Somministrazione}
            title="La somministrazione dei pasti come parte integrante della terapia clinica"
            content=" La nutrizione clinica è una pratica medica che mira a mantenere o migliorare lo stato di nutrizione di un paziente attraverso una serie di interventi preventivi, diagnostici e terapeutici. Tuttavia, la malnutrizione è spesso un rischio per i pazienti ricoverati in ospedale, poiché la loro condizione di salute può compromettere il loro stato nutrizionale. Questo a sua volta può influenzare l'andamento della malattia e la durata dell'ospedalizzazione.
                      Per garantire una nutrizione adeguata ai pazienti, le strutture di dietetica e nutrizione hanno il compito di fornire un intervento nutrizionale personalizzato, che può essere di varia intensità a seconda delle esigenze del paziente. Questo intervento nutrizionale può prevenire e curare situazioni patologiche legate allo stato di nutrizione del paziente.
                      La sorveglianza nutrizionale è un'attività importante per garantire che il paziente stia mangiando adeguatamente e per individuare eventuali rischi di malnutrizione, come problemi dell'apparato digerente o difficoltà masticatorie.
                      Per supportare l'attività di ristorazione ospedaliera, MOHS è una suite di software che copre tutti gli aspetti della nutrizione, compresi quelli culturali e religiosi. Questo sistema di gestione del cibo è connesso con i sistemi aziendali di gestione dei ricoveri, ha una banca dati degli alimenti e gestisce i menu e le ricette, riducendo gli sprechi e ottimizzando il processo produttivo e di consegna. MOHS è uno strumento ideale per le Unità di Nutrizione Clinica delle strutture sanitarie ed assistenziali di qualsiasi dimensione.
                    "
          />
          <ImageTextSection
            image={VassoioPersonalizzato}
            title="Vassoio Personalizzato a letto del degente"
            content=" La gestione del servizio di ristorazione ospedaliera è una sfida complessa che richiede attenzione ai dettagli e una gestione efficiente. Con la nostra suite di software, siamo in grado di offrire una soluzione completa per la gestione della ristorazione personalizzata per pazienti ricoverati.
                      La nostra suite di software consente la creazione di diete personalizzate, l'ordinazione di pasti attraverso tablet e la gestione della produzione di cibi in modo efficiente e preciso. Ci sono circa 25 tipologie di diete standard, ma è sempre possibile richiedere al servizio interno la produzione di diete personalizzate in base a specifiche esigenze.
                      L'uso dei vassoi personalizzati a letto del degente consente la distribuzione rapida e facile dei pasti, mantenendo la giusta temperatura e i sapori dei cibi. I pasti del giorno cambiano ogni giorno con una rotazione settimanale o bisettimanale e alcune scelte fisse disponibili tutti i giorni.
                      La nostra suite di software è stata progettata per essere facile da usare e personalizzabile per soddisfare le esigenze specifiche di ogni ospedale. Offriamo un servizio di assistenza dedicato per garantire un'esperienza senza problemi.
                      La nostra suite di software rappresenta una soluzione completa e innovativa per la gestione della ristorazione personalizzata per pazienti ricoverati. Grazie alla nostra tecnologia avanzata e al nostro team dedicato, siamo in grado di offrire un servizio di ristorazione di alta qualità che soddisfa le esigenze nutrizionali e le preferenze dei pazienti, migliorando la loro esperienza durante il periodo di degenza.
                    "
          />
          <ImageTextSection
            reverse
            image={DietaPersonalizzata}
            title="Le Diete Speciali di tipo Sanitario"
            content=" Grazie alla nostra suite di software è possibile semplificare e rendere più efficiente la gestione della ristorazione ospedaliera con tutte le sue diete speciali di tipo sanitario.
                      Il software viene utilizzato per creare e gestire i menu per le diverse diete speciali, permettendo di programmare i pasti e garantire che ogni paziente riceva un pasto adatto alle proprie esigenze nutrizionali e cliniche.
                      Si può monitorare il consumo del pasto per ogni paziente, garantendo che riceva un apporto nutrizionale e bromatologico adeguato e tenere traccia delle preferenze alimentari e delle allergie dei pazienti, assicurando che i pasti vengano preparati con ingredienti sicuri e accettabili.
                      Si semplifica la gestione delle ricette, permettendo di modificare le ricette esistenti o crearne di nuove, in base alle esigenze dei pazienti. In questo modo, la preparazione dei pasti diventa più efficiente e personalizzata riducendo gli sprechi alimentari.
                      Si riduce il rischio di errori nella preparazione e nella consegna dei pasti, migliorando l'esperienza dei pazienti e aumentando la sicurezza alimentare.
                    "
          />
        </div>
      </Section>

      <Section secondary>
        <div className="container mx-auto pt-12 lg:pt-14 xl:pt-20 testimonials-carousel pb-12 lg:pb-14 xl:pb-20">
          <div className="flex h-full my-auto">
            <div className="prose max-w-5xl flex flex-col gap-4 justify-center text-center mx-auto items-center">
              <h3 className="text-xl lg:text-2xl xl:text-h3 !font-medium">
                MOHS ha cambiato il modo in cui gestiamo le nostre operazioni di cucina ospedaliera. Ci ha aiutato a far fronte alle nostre necessità di prenotare il vassoio personalizzato
                direttamente al letto del degente ed interfacciarci con il centro cottura in modo efficiente e rapido. Davvero un grande aiuto!”
              </h3>
              <p className="text-sm lg:text-body xl:text-2xl">- XXX, Direttore A.O YYY.</p>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="container mx-auto py-12 lg:py-14 xl:py-20">
          <div className="flex flex-col w-full gap-10 lg:gap-20 lg:flex-row">
            <div className="prose w-full lg:w-1/2 lg:mt-10">
              <h2 className="text-body lg:text-4xl font-medium mb-2">Contattaci</h2>
              <p className="lg:leading-6">
                Azienda di Ristorazione Ospedaliera o Gara d'Appalto in scadenza? <br />
                Compila il modulo, teniamoci in contatto!{" "}
              </p>
              <p className="lg:leading-6">Abbiamo molte soluzioni per te e ti aiuteremo a redigere l'offerta tecnica.</p>
            </div>
            <div className="w-full lg:w-1/2">
              <HubspotForm
                region="eu1"
                portalId="145175428"
                formId="806a56b0-10c7-464d-b1a7-8de1d649e040"
                locale="it"
                onSubmit={() => console.log("Submit!")}
                onReady={(form) => console.log("Form ready!")}
                loading={<div>Loading...</div>}
              />
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
}
