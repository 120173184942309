import React from 'react';
import Navbar from "../components/Navbar.js";
import Footer from "../components/Footer.js";
import Card from "../components/Card.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

import logo from "../assets/img/logo.svg";
import headerimg from "../assets/img/slide.jpg";
import Timing from 'components/Timing.js';
import Header from 'components/Header.js';
import Section from 'components/Section.js';
import Hero from "../components/Hero";
import { Link } from 'react-router-dom';

import Hero_bg from "../assets/img/hero_bg.jpg";
import config from "../config.js"
export default function Privacy() {
  return (
    <>
    <Header />
    <div className="overflow-x-hidden">

    <Hero
    title="Software Ristorazione Ospedaliera"
    content="MOHS - Meals Organizer for Hospital System è un software per la gestione della logistica di un centro cottura ospedaliero. La Suite di Software a corredo permettono di gestire con semplicità e velocità la distribuzione di pasti e delle diete personalizzate, garantendo la tracciabilità."
    image={Hero_bg}
    />
    <div className="md:prose-md prose-xl px-4 sm:px-6 lg:px-8 pt-24 pb-6 mx-auto">
    <h1 className='text-2xl font-bold md:text-4xl dark:text-white'>Privacy Policy - SPLIT ® GL S.r.l.s. - MOHS Meals Organizer for Hospital System</h1>
    <h3 className='text-lg sm:text-xl font-semibold mb-2 dark:text-white' >1. TITOLARE E DATA PROTECTION OFFICER</h3>
    <p>Il Titolare del trattamento dati è {config.company}, che ha sede legale in {config.address}.</p>
    <p>Tel. {config.tel} | Email: {config.email}</p>
    <p>Il DPO (Data Protection Officer), è a disposizione per rispondere ad eventuali richieste riguardanti il trattamento dei dati personali;
    è possibile contattare il DPO scrivendo a: dpo@{window.location.hostname} o contattando il numero di telefono: {config.tel}.</p>
    <h3 className='text-lg sm:text-xl font-semibold mb-2 dark:text-white'>2. LUOGO DI TRATTAMENTO DEI DATI</h3>
    <p>I trattamenti connessi ai servizi web di questo sito hanno luogo presso la predetta sede di {config.company} e sono curati solo da personale tecnico dell'Ufficio incaricato del trattamento, oppure da eventuali incaricati di occasionali operazioni di manutenzione.</p>
    <p>Nessun dato derivante dal servizio web viene comunicato o diffuso.</p>
    <p>I dati personali forniti dagli Utenti che inoltrano richieste di invio di materiale informativo sono utilizzati al solo fine di eseguire il servizio o la prestazione richiesta e sono comunicati a terzi nel solo caso in cui ciò sia a tal fine necessario.</p>
    <h3 className='text-lg sm:text-xl font-semibold mb-2 dark:text-white'>3. TIPI DI DATI TRATTATI</h3>

    <strong>Dati di navigazione</strong>

    <p>I sistemi informatici e le procedure software preposte al funzionamento di questo sito web acquisiscono, nel corso del loro normale esercizio, alcuni dati personali la cui trasmissione è implicita nell'uso dei protocolli di comunicazione di Internet.</p>
    <p>Si tratta di informazioni che non sono raccolte per essere associate a interessati identificati, ma che per loro stessa natura potrebbero, attraverso elaborazioni ed associazioni con dati detenuti da terzi, permettere di identificare gli Utenti.</p>
    <p>In questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio dei computer utilizzati dagli Utenti che si connettono al sito, gli indirizzi in notazione URI (Uniform Resource Identifier) delle risorse richieste, l'orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta data dal server (buon fine, errore, ecc.) ed altri parametri relativi al sistema operativo e all'ambiente informatico dell'Utente.</p>
    <p>Questi dati vengono utilizzati al solo fine di ricavare informazioni statistiche anonime sull'uso del sito e per controllarne il corretto funzionamento. I dati potrebbero essere utilizzati per l'accertamento di responsabilità in caso di ipotetici reati informatici ai danni del sito.</p>

    <strong>Dati forniti volontariamente dall'Utente</strong>

    <p>L'invio facoltativo, esplicito e volontario di posta elettronica agli indirizzi indicati su questo sito o l'invio di dati personali e non in chat comporta la successiva acquisizione dell'indirizzo del mittente, necessario per rispondere alle richieste, nonché degli eventuali altri dati personali inseriti nella missiva o nel contenuto della chat.</p>
    <p>La raccolta di dati personali come nome, cognome, azienda, città, sito web, email, numero di telefono può avvenire anche attraverso la compilazione volontaria dei moduli di contatto presenti nel sito web.</p>

    <p>I dati raccolti attraverso l'invio volontario di posta elettronica, i moduli di contatto presenti nel sito web e il servizio chat possono essere utilizzati per le seguenti finalità:</p>
    <ul className='list-disc'>
    <li><strong>RICHIESTE DELL'UTENTE</strong>
    <p>Quando l'Utente decide di richiedere un preventivo o delle informazioni, attraverso l'invio volontario di posta elettronica, i moduli di contatto e il servizio chat, {config.company} utilizza le informazioni di contatto (indirizzo email e numero di telefono) per rispondere alle richieste espresse dall'Utente o a quelle indicate dall'intestazione del modulo di contatto. In particolare, quando l'Utente inserisce i propri dati in un modulo di contatto presente nel sito web, tramite click su apposito pulsante esprime il consenso per le finalità sopraesposte;</p></li>

    <li><strong>STATISTICHE</strong>
    <p>{config.company} si riserva la facoltà di effettuare indagini statistiche, monitoraggio di campagne pubblicitarie, elaborazione di report relativi ai risultati ottenuti anche per verificare il livello di soddisfazione degli Utenti e di fidelizzazione;</p></li>

    <li><strong>PROFILAZIONE</strong>
    <p>{config.company} effettua attività di profilazione, ovvero attività di analisi ed elaborazione di informazioni relative a Utenti o Clienti, al fine di suddividere gli interessati in gruppi omogenei per comportamenti o caratteristiche sempre più specifici e per proporre ad essi prodotti e servizi sempre più mirati e conformati alle specifiche esigenze dell'Utente. Per effettuare attività di profilazione è necessario che l'Utente presti il consenso per le finalità di profilazione a {config.company} in fase di conferimento dati. Gli Utenti possono manifestare il diritto di negazione del proprio consenso per tutte le attività di profilazione, facendo richiesta tramite email e/o numero indicati nel Paragrafo "Diritti degli interessati" della presente Informativa;</p></li>

    <li><strong>MARKETING DIRETTO</strong>
    <p>{config.company} conserva i dati rilasciati dai propri Utenti attraverso il sito web {window.location.hostname} per l'invio di eventuali future comunicazioni informative e promozionali anche in linea con le preferenze manifestate dall'Utente nell'ambito della navigazione in rete. Tali comunicazioni potranno avvenire attraverso l'utilizzo di strumenti tradizionali quali chiamate tramite operatore e posta cartacea, e/o automatizzati come ad esempio email, SMS, MMS, fax e altri mezzi equiparabili. Le comunicazioni possono avere ad oggetto materiali informativi, proposte commerciali, promozioni, istruzioni su come utilizzare al meglio i servizi, aggiornamento servizi, problemi relativi al funzionamento. {config.company} inoltra comunicazioni informative e promozionali esclusivamente per conto della stessa. Per ricevere le comunicazioni informative e promozionali è necessario che l'Utente presti il consenso per le finalità di marketing a {config.company} in fase di conferimento dati. Gli Utenti possono richiedere in ogni momento di modificare le preferenze di contatto e manifestare il diritto di negazione del proprio consenso per tutte le attività promozionali e di marketing, facendo richiesta tramite email e/o numero indicati nel Paragrafo "Diritti degli interessati" della presente Informativa.</p></li>
    </ul>

    <p>Specifiche informative di sintesi verranno progressivamente riportate o visualizzate nelle pagine del sito predisposte per particolari servizi a richiesta.</p>

    <h3 className='text-lg sm:text-xl font-semibold mb-2 dark:text-white'>4. COOKIES</h3>

    <p>Questo sito utilizza cookie tecnici e di profilazione di prima e terza parte.</p>

    <p>Per consultare la nostra informativa estesa sui cookie, vai al seguente indirizzo: <Link to="/cookie">Cookie</Link></p>
    <p>Se vuoi disattivare i cookie di profilazione della Suite, vai al seguente indirizzo: <Link to="/cookie">Disattiva</Link></p>
    <h3 className='text-lg sm:text-xl font-semibold mb-2 dark:text-white'>5. FACOLTATIVITA' DEL CONFERIMENTO DEI DATI</h3>
    <p>A parte quanto specificato per i dati di navigazione, l'Utente è libero di fornire i dati personali riportati nei moduli di richiesta a {config.company} o comunque indicati in contatti con l'Ufficio per sollecitare l'invio di materiale informativo o di altre comunicazioni.</p>
    <p>Il loro mancato conferimento può comportare l'impossibilità di ottenere quanto richiesto.</p>
    <p>Per completezza va ricordato che in alcuni casi (non oggetto dell'ordinaria gestione di questo sito) l'Autorità può richiedere notizie e informazioni ai sensi dell' art. 156, comma 1, del D.Lgs. 196/2003, ai fini del controllo sul trattamento dei dati personali. In questi casi la risposta è obbligatoria a pena di sanzione amministrativa.</p>

    <h3 className='text-lg sm:text-xl font-semibold mb-2 dark:text-white'>6. FINALITA' DEL TRATTAMENTO</h3>
    <ul className='list-disc'>
      <li><strong>Erogazione Servizi</strong>
      <p>Consentire l'erogazione dei Servizi richiesti e la raccolta ed elaborazione dei dati per fini di gestione operativa, tecnica, amministrativa e contabile del rapporto contrattuale instaurato;</p>
      </li>
      <li><strong>Richieste utente</strong>
      <p>Rispondere a richieste di assistenza o di informazioni che riceveremo via e-mail, telefono o chat o attraverso l'iscrizione ai moduli di contatto.</p>
      </li>
      <li><strong>Statistiche</strong>
      <p>Monitorare campagne pubblicitarie, elaborazione studi, ricerche, statistiche, report sui risultati ottenuti e per verificare il livello di soddisfazione degli Utenti e di fidelizzazione.</p>
      </li>
      <li><strong>Profilazione</strong>
      <p>Svolgere attività di analisi ed elaborazione automatizzate di informazioni relative a Utenti o Clienti, al fine di suddividerli in gruppi omogenei per comportamenti o caratteristiche specifiche e per proporre ad essi prodotti e servizi sempre più mirati e conformati alle specifiche esigenze dell'Utente.</p>
      </li>
      <li><strong>Marketing Diretto</strong>
      <p>Inviare eventuali future comunicazioni informative (istruzioni su come utilizzare al meglio i servizi, aggiornamento servizi, problemi relativi al funzionamento,...) e promozionali (proposte commerciali, promozioni,...) attraverso strumenti tradizionali quali chiamate tramite operatore e posta cartacea, e/o automatizzati come ad esempio email, SMS, MMS, fax e altri mezzi equiparabili.</p>
      </li>
    </ul>
    <h3 className='text-lg sm:text-xl font-semibold mb-2 dark:text-white'>7. BASE LEGALE E NATURA OBBLIGATORIA O FACOLTATIVA DEL TRATTAMENTO</h3>
    <p>La base legale del trattamento di Dati Personali per le finalità a e b del punto 8 è l'art. 6, lett. b) del Regolamento poichè il trattamento è necessario per l'erogazione dei servizi.</p>
    <p>Il conferimento dei Dati è facoltativo ma il mancato conferimento potrebbe comportare l'impossibilità di attivare i Servizi.</p>
    <p>Il trattamento per finalità statistiche, di marketing diretto e profilazione si basa sul consenso al trattamento dei dati personali dell'interessato (Art. 6, lett. a) del Regolamento. L'interessato può revocare il proprio consenso in qualsiasi momento.</p>

    <h3 className='text-lg sm:text-xl font-semibold mb-2 dark:text-white'>8. DESTINATARI DEI DATI PERSONALI</h3>
    Nessun dato viene comunicato, diffuso o ceduto a terzi per finalità commerciali.
    Tuttavia, i Dati Personali raccolti potranno essere condivisi con:
    <ul className='list-disc'>
    <li>persone, società o studi professionali che prestano a {config.company} attività di assistenza e consulenza contabile, amministrativa, legale e finanziaria;</li>
    <li>soggetti che svolgono attività di manutenzione tecnica;</li>
    <li>soggetti, enti od autorità a cui sia obbligatorio comunicare i Dati Personali in forza di disposizioni di legge o di ordini delle autorità (ad esempio, indagini di natura penale);</li>
    </ul>
    <h3 className='text-lg sm:text-xl font-semibold mb-2 dark:text-white'>9. TRASFERIMENTO DEI DATI PERSONALI</h3>
    <p>I Dati Personali raccolti non sono condivisi al di fuori dello Spazio Economico Europeo.</p>
    <h3 className='text-lg sm:text-xl font-semibold mb-2 dark:text-white'>10. CONSERVAZIONE DEI DATI</h3>
    <ol className='list-decimal'>
    <li><strong>Erogazione Servizi</strong>
    <p>I Dati Personali saranno conservati per il tempo strettamente necessario a raggiungere la finalità o fino al tempo previsto dallo specifico obbligo o norma di legge applicabile.</p></li>
    <li><strong>Richieste utente</strong>
    <p>I dati verranno conservati per il tempo necessario ad evadere le richieste, precisamente per un arco temporale che va da una settimana a due settimane.</p></li>
    <li><strong>Statistiche</strong>
    <p>I Dati Personali saranno trattati fino alla revoca del consenso oppure fino a dieci anni dopo che l'utente abbia cessato di essere cliente di {config.company} o si sia registrato nei Moduli presenti sul Sito.</p></li>
    <li><strong>Profilazione</strong>
    <p>I Dati Personali saranno trattati fino alla revoca del consenso oppure fino a dieci anni dopo che l'utente abbia cessato di essere cliente di {config.company} o si sia registrato nei Moduli presenti sul Sito.</p></li>
    <li><strong>Marketing Diretto</strong>
    <p>I Dati Personali saranno trattati fino alla revoca del consenso oppure fino a dieci anni dopo che l'utente abbia cessato di essere cliente di {config.company} o si sia registrato nei Moduli presenti sul Sito.</p></li>
    </ol>

    <p>L’applicazione della normativa vigente (a titolo di esempio, non esaustivo, civilistica, fiscale, societaria, previdenziale,...), o l'adempimento ad obblighi legali, o l'accertamento, esercizio, difesa di un diritto in sede giudiziaria, o ogniqualvolta le autorità giurisdizionali esercitino le loro funzioni, potrebbero far emergere la necessità che alcuni dati personali debbano essere ulteriormente conservati al fine di rispettare le tempistiche previste da detti obblighi o normative.</p>
    <p>E' possibile richiedere maggiori informazioni in merito al periodo di conservazione dei dati contattando il Titolare del trattamento o il DPO.</p>

    <h3 className='text-lg sm:text-xl font-semibold mb-2 dark:text-white'>11. DIRITTI DEGLI INTERESSATI</h3>
    <p>L'interessato ha diritto di chiedere a {config.company}, in qualunque momento:</p>
    <ul className='list-disc'>
    <li>l'accesso ai suoi Dati Personali (Art. 15);</li>
    <li>la rettifica (Art. 16) o la cancellazione degli stessi, "diritto all'oblio" (Art. 17);</li>
    <li>di opporsi al loro trattamento nei casi previsti dall'articolo 21 del Regolamento;</li>
    <li>di richiedere la limitazione del trattamento nei casi previsti dall'art. 18 del Regolamento;</li>
    <li>di ottenere in un formato strutturato, di uso comune e leggibile da dispositivo automatico i dati che lo riguardano forniti a un titolare del trattamento e ha il diritto di trasmettere tali dati a un altro titolare del trattamento«portabilità», (Art. 20);</li>
    </ul>
    <p>Le richieste vanno rivolte a: Email: dpo@{window.location.hostname} - Tel: {config.tel}.</p>
    <p>L'interessato, qualora ritenga che il trattamento dei suoi dati sia contrario alla normativa in vigore ha diritto di proporre reclamo all'autorità di controllo competente (Garante per la Protezione dei Dati Personali), ai sensi dell'art. 77 del Regolamento.</p>
    </div>
    </div>
    <Footer />
    </>
    );
  }
